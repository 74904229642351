<template>
  <div
    class="bg-primary-red text-white px-4 py-3 rounded-sm leading-4 text-lg font-bold"
  >
    Basket {{ assetsCount ? '(' + assetsCount + ' Assets)' : '' }}
  </div>
</template>

<script>
export default {
    name: 'BasketTab',

    props: {
        assetsCount: {
            type: [Number, String],
            default: ''
        }
    }
};
</script>
